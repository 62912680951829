//
//
//

export default {
    name: "HeaderAnimation",
    data() {
        return {
            heightHeader: 0,
            // headerInter: null,
            catalogInter: null,
            // observerHeader: null,
            observerCatalog: null,
        };
    },
    mounted() {
        this.headerAnimation();
    },
    beforeDestroy() {
        this.observerCatalog.unobserve(this.catalogInter);
        // this.observerHeader.unobserve(this.headerInter);
    },
    methods: {
        headerAnimation() {
            if (window.matchMedia("(min-width: 1280px)").matches) {
                this.heightHeader = 64;
            } else {
                this.heightHeader = 55;
            }
            // this.headerInter = document.querySelector('.js-header-inter');
            // this.observerHeader = new IntersectionObserver(
            //     ([e]) => {
            //         document.body.classList.toggle('header-fix', e.intersectionRatio < 1);
            //     },
            //     { rootMargin: '1px 0px 0px 0px', threshold: [0] },
            // );
            // this.observerHeader.observe(this.headerInter);
            this.catalogInter = document.querySelector(".js-catalog-inter");
            this.observerCatalog = new IntersectionObserver(
                ([e]) => {
                    document.body.classList.toggle("category-fix", e.intersectionRatio < 1);
                },
                {
                    rootMargin: `-${this.heightHeader}px 0px 0px 0px`,
                    threshold: [0],
                }
            );
            this.observerCatalog.observe(this.catalogInter);
        },
    },
};
