//
//
//

import { mapState } from "vuex";
import { CatalogItemType } from "~/service/catalog-router/types";
import cityLinksHelper from "~/util/city-links-helper";

export default {
    name: "CurrentCategory",
    data() {
        return {
            allowChangeUrl: true,
            observerCategory: null,
            observerBanner: null,
            blockAutoScrollOnce: false,
            scrollInProcess: false,
            windowHeight: null,
            offsetScroll: null,
        };
    },
    computed: {
        ...mapState("account", ["showMobileAppBanner"]),
        currentCatalogItem() {
            return this.getCatalogItemByRoutePath(this.$route.path);
        },
        isProductRoute() {
            return this.currentCatalogItem?.type === CatalogItemType.PRODUCT;
        },
    },
    watch: {
        $route: {
            handler(to, from) {
                if (from) {
                    // Сработал НЕ immediate
                    this.onRouteChange();
                } else if (process.client) {
                    // Сработал immediate
                    // Вызовем переход к нужной категории у Swiper
                    // TODO: Переделать по типу ожидания ивента как _clientInitializedObservable
                    this.categorySlide();
                    this.$nuxt.$once("on-catalog-categories-swiper-init", () => this.categorySlide());
                }
                this.$store.dispatch("products/findCurrentViewCategoryId");
            },
            immediate: true,
        },
        "$store.state.products.products"(newVal, oldVal) {
            if (process.client) {
                this.$store.commit("products/setNextScrollSpeed", 1);
                this.scrollToCurrentPathCategoryItem();
            }
        },
    },
    created() {
        if (this.isProductRoute) {
            // Ссылка на продукт - откроем попап
            this.showProductPopup();
        }
    },
    mounted() {
        if (this.$route.query && this.$route.query.change_city == "1") {
            this.$store.commit("products/setNextScrollSpeed", 1);
        }
        this.scrollOnMounted();
        this.$nuxt.$on("scroll-to-path", this.onScrollToPath);
        if (process.env.NODE_ENV === "development") {
            // Хак для перенастройки обсерверов на категории
            setTimeout(this.reconnectObservers, 1);
        }
    },
    beforeDestroy() {
        this.$nuxt.$off("scroll-to-path", this.onScrollToPath);

        if (this.catalogScrollTimeout) {
            clearTimeout(this.catalogScrollTimeout);
            this.catalogScrollTimeout = null;
        }

        window.removeEventListener("scroll", this.onCatalogScroll);
    },
    methods: {
        onScrollToPath(path) {
            this.scrollToPath(path);
        },
        getCatalogItemByRoutePath(path) {
            return this.$catalogRouter.getItemByPath(cityLinksHelper.cleanCityPath(path));
        },
        allCategoriesMap(f) {
            if (typeof f !== "function") {
                return;
            }
            const allCategories = document.querySelectorAll(".js-category-observer");
            allCategories.forEach((category) => f(category));
        },
        getBannerElement() {
            return document.querySelector(".js-main-slider");
        },
        connectCategoryObserver() {
            const observerCategory = new IntersectionObserver(this.intersectionCallback, {
                rootMargin: "-60% 0px -40% 0px",
                threshold: [0],
            });
            this.observerCategory = observerCategory;

            const observeCategories = () => {
                this.allCategoriesMap((category) => observerCategory.observe(category));
                // Удаляем слушатель, чтобы не наблюдать повторно
                window.removeEventListener("scroll", observeCategories);
            };

            window.addEventListener("scroll", () => {
                if (window.scrollY > 100) {
                    observeCategories();
                }
            });
        },
        intersectionCallback(entries, observer) {
            entries.forEach((entry) => {
                if (entry.isIntersecting && !this.scrollInProcess) {
                    const category = entry.target.querySelector(".js-category-elem");
                    const match = category.id.match(/[0-9]+/);

                    if (!match) {
                        return;
                    }

                    const path = cityLinksHelper.getNuxtLinkToPath(
                        this.$catalogRouter.getCategoryPathById(match[0]),
                        cityLinksHelper.getCityIdFromVuex(this.$store)
                    );

                    if (!path || path === this.$router.currentRoute.path) {
                        return;
                    }
                    this.onCatalogScrollChangeURL(path);
                }
            });
        },
        disconnectCategoryObserver() {
            if (!this.observerCategory) {
                return;
            }
            this.allCategoriesMap((category) => this.observerCategory.unobserve(category));
            this.observerCategory = null;
        },
        connectBannerObserver() {
            const vm = this;
            const observeBanner = new IntersectionObserver(
                (entries) => {
                    // if (window.scrollY >= 50) {
                    //     this.$store.commit("toogleNewYearAnimation", false);
                    // }
                    // else {
                    //     this.$store.commit("toogleNewYearAnimation", true);
                    // }

                    entries.forEach(async (entry) => {
                        if (
                            this.allowChangeUrl &&
                            entry.isIntersecting &&
                            cityLinksHelper.cleanCityPath(this.$router.currentRoute.fullPath, true) !== ""
                        ) {
                            try {
                                await this.$router.replace(
                                    cityLinksHelper.getNuxtLinkToPath(
                                        cityLinksHelper.cleanCityPath("/"),
                                        cityLinksHelper.getCityIdFromVuex(this.$store)
                                    )
                                );
                            } catch (err) {
                                if (process.env.NODE_ENV === "development") {
                                    console.warn("Error while router.replace", err);
                                }
                            }
                        }
                    });
                },
                { rootMargin: "50px 0px 0px 0px", threshold: [1] }
            );

            this.observerBanner = observeBanner;
            this.observerBanner?.observe(this.getBannerElement());
        },
        disconnectBannerObserver() {
            if (!this.observerBanner) {
                return;
            }
            this.observerBanner?.unobserve(this.getBannerElement());
            this.observerBanner = null;
        },
        reconnectObservers() {
            this.disconnectBannerObserver();
            this.disconnectCategoryObserver();

            this.connectBannerObserver();
            this.connectCategoryObserver();
        },
        async onCatalogScrollChangeURL(path) {
            // Заблокируем автопрокрутку к категории на время смены урла
            // TODO: Заблокировать router.replace, если текущий router.currentRoute.path относится к продукту, а path перехода - к категории

            if (!this.allowChangeUrl) {
                return;
            }

            this.blockAutoScrollOnce = true;
            try {
                await this.$router.replace({ path });
            } catch (err) {
                // console.warn("Меняю url категории на скролл");
                // console.error(err);
            }

            this.categorySlide();
        },
        categorySlide() {
            if (this.currentCatalogItem?.type !== CatalogItemType.CATEGORY) {
                return;
            }

            this.$nextTick(() => {
                const swiperCategory = document.querySelector("#swiper-category").swiper;
                const activeCategory = document.querySelector(
                    `.categories__link.js-link-category_id-${this.currentCatalogItem.id}`
                );

                if (activeCategory && swiperCategory) {
                    const index = activeCategory.dataset.index;
                    const indexBegin = ["0", "1", "2"];
                    swiperCategory.slideTo(indexBegin.includes(index) ? 0 : index, 1000);
                }
            });
        },
        emitOnProductPopupCloseFunction() {
            if (!this.isProductRoute) {
                return;
            }

            this.$nuxt.$emit("popup-product-close-function", () => {
                this.allowChangeUrl = true;
                this.reconnectObservers();
            });
        },
        async showProductPopup() {
            this.allowChangeUrl = false;
            this.emitOnProductPopupCloseFunction();
            await this.$store.dispatch("productPopup/showProductByRoute", this.$route);
        },
        onRouteChange() {
            // Скроем попап продукта
            // this.$nuxt.$emit('hide-popup-product');
            // console.log('** clearing productPopup onRouteChange');
            // this.$store.dispatch('productPopup/clearState');

            if (!this.currentCatalogItem) {
                return;
            }

            if (this.currentCatalogItem.type === CatalogItemType.PRODUCT) {
                // Если урл продукта - попросить показать попап
                this.showProductPopup();
            }

            this.categorySlide();
        },
        async scrollToCurrentPathCategoryItem() {
            if (!this.currentCatalogItem) {
                return;
            }
            await this.scrollToCategoryItem(this.currentCatalogItem);
        },
        scrollToPath(path) {
            const catalogItem = this.$catalogRouter.getItemByPath(cityLinksHelper.cleanCityPath(path));
            if (!catalogItem) {
                return;
            }

            this.scrollToCategoryItem(catalogItem);
        },
        scrollToCategoryItem(catalogItem) {
            let existDOMElement;
            let existDOMElementF = () => {};

            if (catalogItem.type === CatalogItemType.CATEGORY) {
                existDOMElementF = () => document.getElementById(`category_id-${catalogItem.id}`);
            } else {
                existDOMElementF = () => document.getElementsByClassName(`js-product_id-${catalogItem.id}`)[0];
            }

            return new Promise((resolve) => {
                setTimeout(() => {
                    const domElement = existDOMElementF();

                    if (!domElement) {
                        resolve();
                        return;
                    }
                    this.setDimensions();
                    this.$scrollTo(domElement, 0, {
                        offset: this.offsetScroll,
                        cancelable: false,
                        force: false,
                        onDone: resolve,
                    });
                }, 1);
            });
        },
        setDimensions() {
            // Сдвиг скролла при выборе категории
            const categoryPanel = document.querySelector(".main-top-line-wrap").getBoundingClientRect().height;
            const topLinePanel = document.querySelector(".wrap-top-line").getBoundingClientRect().height;
            const gap = 10;

            // if (this.$screen.width > 1280) {
            //     this.offsetScroll = -140;
            //     return;
            // }
            //
            // if (this.$screen.width >= 1024) {
            //     this.offsetScroll = -140;
            //     return;
            // }

            this.offsetScroll = -categoryPanel - topLinePanel - gap;
        },
        async scrollOnMounted() {
            await this.scrollToCurrentPathCategoryItem();
            this.$store.commit("products/setViewOpacity", 1);

            if (this.currentCatalogItem?.type !== CatalogItemType.PRODUCT) {
                // Ссылка на категорию - проскроллим к ней
                this.reconnectObservers();
            }

            this.emitOnProductPopupCloseFunction();
        },
    },
};
